<template>
  <validation-observer ref="forDesignersValidation">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="form.lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-image-title">Tytuł zdjęcia</span>
          </template>
          <validation-provider #default="{ errors }" name="Tytuł zdjęcia" rules="required|max:150">
            <b-form-input id="v-image-title" placeholder="Tytuł zdjęcia" v-model="form.title" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-image">Zdjęcie nagłówkowe</span>
          </template>
          <b-button @click="toggleShowModal('title_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie nagłówkowe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Zdjęcie nagłówkowe" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Treść</h4>
        <b-form-group>
          <template slot="label">
            <span label-for="v-header-note">Górna notka</span>
          </template>
          <validation-provider #default="{ errors }" name="Górna notatka" rules="required|max:150">
            <b-form-input id="v-header-note" placeholder="Górna notka" v-model="form.header" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Treść">
          <validation-provider #default="{ errors }" name="Treść" rules="required|max:1000">
            <b-form-textarea
              id="v-content"
              v-model="form.introduce"
              :options="editorOption"
              :disabled="!form.lang"
              rows="10" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Przedstawiciele handlowi</h4>
      </b-col>

      <!-- Form repeater -->
      <b-form-group>
        <b-col v-for="(item, index) in form.salesmen" :id="item.id" :key="item.id" ref="row">
          <!-- County -->
          <b-form-group label="Województwo" :label-for="`county-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Województwo #${index}`" rules="required">
              <b-form-input
                v-model="form.salesmen[index].county"
                :id="`county-${item.id}-${item.id}`"
                placeholder="Województwo" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Description -->

          <b-form-group label="Imię i nazwisko" :label-for="`name-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Imię i nazwisko #${index}`" rules="required|max:150">
              <b-form-textarea
                v-model="form.salesmen[index].name"
                :id="`name-${item.id}-${item.id}`"
                placeholder="Imię i nazwisko" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Telefon" :label-for="`phone-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Telefon #${index}`" rules="required|max:150">
              <b-form-textarea
                v-model="form.salesmen[index].phone"
                :id="`phone-${item.id}-${item.id}`"
                placeholder="Telefon" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email" :label-for="`email-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Email #${index}`" rules="required|max:150">
              <b-form-textarea
                v-model="form.salesmen[index].email"
                :id="`email-${item.id}-${item.id}`"
                placeholder="Email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Remove Button -->

          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeSalesman(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Usuń</span>
          </b-button>

          <b-col>
            <hr />
          </b-col>
        </b-col>

        <b-col>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            @click="addSalesman"
            :disabled="!form.lang || form.salesmen.length >= 3">
            <span>Dodaj przedstawiciela</span>
          </b-button>
        </b-col>
      </b-form-group>

      <!-- 3D MODELS START -->
      <b-col>
        <h4 class="mt-3 mb-2">Modele 3D</h4>
        <b-form-group>
          <template slot="label">
            <span label-for="v-three-d-title">Modele 3D - tytuł</span>
          </template>
          <validation-provider #default="{ errors }" name="Modele 3D - tytuł" rules="required|max:150">
            <b-form-input
              id="v-three-d-title"
              placeholder="Modele 3D - tytuł"
              v-model="form.three_d_models_title"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-three-d-image">Modele 3D - zdjęcie</span>
          </template>
          <b-button @click="toggleShowModal('three_d_models_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Modele 3D - zdjęcie" rules="required|size:5000">
            <input
              v-if="files.three_d_models_image"
              type="hidden"
              name="Modele 3D - zdjęcie"
              v-model="files.three_d_models_image.file" />
            <input v-else type="hidden" name="Modele 3D - zdjęcie" v-model="files.three_d_models_image" />

            <ImageSelector
              v-if="showImagesModal.three_d_models_image"
              :files="filesArray"
              :show-modal="showImagesModal.three_d_models_image"
              @closeModal="toggleShowModal('three_d_models_image')"
              @submit="(fileList) => handleFile(fileList[0], 'three_d_models_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.three_d_models_image"
            :image="files.three_d_models_image"
            @deleteImage="() => handleDelete('three_d_models_image')" />
        </b-form-group>
      </b-col>

      <b-form-group label="Modele 3D - plik">
        <validation-provider #default="{ errors }" name="Modele 3D - plik" rules="required">
          <b-form-file
            id="v-three-d-file"
            v-model="form.three_d_models_file"
            :state="Boolean(form.three_d_models_file)"
            browse-text="Wybierz plik"
            placeholder="Wybierz lub przeciągnij plik"
            drop-placeholder="Przeciągnij tu plik"
            accept=".jpg, .png, .gif, .jpeg, .svg, .bmp, .doc, .docx, .zip, .pdf, ."
            :disabled="!form.lang" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- 3D MODELS - END -->

      <!-- Catalog START -->
      <b-col>
        <h4 class="mt-3 mb-2">Katalog</h4>
        <b-form-group>
          <template slot="label">
            <span label-for="v-catalog-title">Katalog - tytuł</span>
          </template>
          <validation-provider #default="{ errors }" name="Katalog - tytuł" rules="required|max:150">
            <b-form-input
              id="v-catalog-title"
              placeholder="Katalog - tytuł"
              v-model="form.catalog_title"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-catalog-image">Katalog - zdjęcie</span>
          </template>
          <b-button @click="toggleShowModal('catalog_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Katalog - zdjęcie" rules="required|size:5000">
            <input
              v-if="files.catalog_image"
              type="hidden"
              name="Katalog - zdjęcie"
              v-model="files.catalog_image.file" />
            <input v-else type="hidden" name="Katalog - zdjęcie" v-model="files.catalog_image" />

            <ImageSelector
              v-if="showImagesModal.catalog_image"
              :files="filesArray"
              :show-modal="showImagesModal.catalog_image"
              @closeModal="toggleShowModal('catalog_image')"
              @submit="(fileList) => handleFile(fileList[0], 'catalog_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.catalog_image"
            :image="files.catalog_image"
            @deleteImage="() => handleDelete('catalog_image')" />
        </b-form-group>
      </b-col>

      <b-form-group>
        <template slot="label">
          <span label-for="v-catalog-link">Katalog - link</span>
        </template>
        <validation-provider #default="{ errors }" name="Katalog - link" rules="required|max:150">
          <b-form-input
            id="v-catalog-link"
            placeholder="Katalog - link"
            v-model="form.catalog_link"
            :disabled="!form.lang" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- Catalog - END -->

      <!-- DOCUMENTATION START -->
      <b-col>
        <h4 class="mt-3 mb-2">Dokumentacja</h4>
        <b-form-group>
          <template slot="label">
            <span label-for="v-documentation-title">Dokumentacja - tytuł</span>
          </template>
          <validation-provider #default="{ errors }" name="Dokumentacja - tytuł" rules="required|max:150">
            <b-form-input
              id="v-documentation-title"
              placeholder="Dokumentacja - tytuł"
              v-model="form.documentation_title"
              :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            <span label-for="v-documentation-image">Dokumentacja - zdjęcie</span>
          </template>
          <b-button @click="toggleShowModal('documentation_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Dokumentacja - zdjęcie" rules="required|size:5000">
            <input
              v-if="files.documentation_image"
              type="hidden"
              name="Dokumentacja - zdjęcie"
              v-model="files.documentation_image.file" />
            <input v-else type="hidden" name="Dokumentacja - zdjęcie" v-model="files.documentation_image" />

            <ImageSelector
              v-if="showImagesModal.documentation_image"
              :files="filesArray"
              :show-modal="showImagesModal.documentation_image"
              @closeModal="toggleShowModal('documentation_image')"
              @submit="(fileList) => handleFile(fileList[0], 'documentation_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.documentation_image"
            :image="files.documentation_image"
            @deleteImage="() => handleDelete('documentation_image')" />
        </b-form-group>
      </b-col>

      <b-form-group>
        <template slot="label">
          <span label-for="v-documentation-link">Dokumentacja - link</span>
        </template>
        <validation-provider #default="{ errors }" name="Dokumentacja - link" rules="required|max:150">
          <b-form-input
            id="v-documentation-link"
            placeholder="Dokumentacja - link"
            v-model="form.documentation_link"
            :disabled="!form.lang" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- DOCUMENTATION - END -->

      <!-- REFERENCES - START -->
      <b-col>
        <h4 class="mt-3 mb-2">Referencje</h4>
      </b-col>

      <!-- Form repeater -->
      <b-form-group>
        <b-col v-for="(item, index) in form.references" :id="item.id" :key="item.id" ref="row">
          <!-- Title -->
          <b-form-group label="Tytuł" :label-for="`reference-title-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Tytuł #${index}`" rules="required|max:150">
              <b-form-input
                v-model="form.references[index].title"
                :id="`reference-title-${item.id}-${item.id}`"
                placeholder="Tytuł" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Text -->
          <b-form-group label="Tekst" :label-for="`reference-text-${item.id}-${item.id}`">
            <validation-provider #default="{ errors }" :name="`Tekst #${index}`" rules="required|max:500">
              <b-form-textarea
                v-model="form.references[index].text"
                :id="`reference-text-${item.id}-${item.id}`"
                placeholder="Tekst" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <template slot="label">
              <span label-for="v-catalog-image">Referencja - zdjęcie</span>
            </template>
            <b-button @click="toggleShowModal(`reference_image_${item.id}`)" :disabled="!form.lang">
              Wybierz zdjęcie
            </b-button>
            <validation-provider
              #default="{ errors }"
              :name="`Referencja - zdjęcie ${index + 1}`"
              rules="required|size:5000">
              <input
                v-if="files[`reference_image_${item.id}`]"
                type="hidden"
                :name="`Referencja - zdjęcie ${index + 1}`"
                v-model="files[`reference_image_${item.id}`].file" />
              <input
                v-else
                type="hidden"
                :name="`Referencja - zdjęcie ${index + 1}`"
                v-model="files[`reference_image_${item.id}`]" />

              <ImageSelector
                v-if="showImagesModal[`reference_image_${item.id}`]"
                :files="filesArray"
                :show-modal="showImagesModal[`reference_image_${item.id}`]"
                @closeModal="toggleShowModal(`reference_image_${item.id}`)"
                @submit="(fileList) => handleFile(fileList[0], `reference_image_${item.id}`)" />
              <small class="text-danger d-block">{{ errors[0] }}</small>
            </validation-provider>

            <ImagePreview
              v-if="files[`reference_image_${item.id}`]"
              :image="files[`reference_image_${item.id}`]"
              @deleteImage="() => handleDelete(`reference_image_${item.id}`)" />
          </b-form-group>

          <!-- Remove Button -->
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeReference(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Usuń</span>
          </b-button>

          <b-col>
            <hr />
          </b-col>
        </b-col>

        <b-col>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            @click="addReference"
            :disabled="!form.lang">
            <span>Dodaj referencję</span>
          </b-button>
        </b-col>
      </b-form-group>

      <!-- REFERENCES - END -->

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" :disabled="!form.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  components: {
    quillEditor,
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      languages: getLanguages(),
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
      form: this.page,
    };
  },

  created() {
    this.form.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(
      ['title_image', 'three_d_models_image', 'catalog_image', 'documentation_image'],
      ['title_image', 'three_d_models_image', 'catalog_image', 'documentation_image'],
    );

    this.fetchByLocale();
  },

  watch: {
    async page(newValue) {
      if (this.form.value === newValue) return;

      await Promise.all([
        this.setImage(this.page.title_image, 'title_image'),
        this.setImage(this.page.three_d_models_image, 'three_d_models_image'),
        this.setImage(this.page.catalog_image, 'catalog_image'),
        this.setImage(this.page.documentation_image, 'documentation_image'),
      ]);

      if (newValue.references) {
        this.setFileUploadData(
          newValue.references.map((reference) => `reference_image_${reference.id}`),
          newValue.references.map((reference) => `reference_image_${reference.id}`),
        );

        newValue.references.forEach((reference) => {
          this.setImage(reference.image, `reference_image_${reference.id}`);
        });
      }

      const salesmen = newValue.salesmen.map((salesman) => {
        return {
          ...salesman,
          name: salesman.fullname,
          county: salesman.county.name,
        };
      });

      const references = newValue.references.map((reference) => {
        return {
          ...reference,
          title: reference.name,
          text: reference.description,
        };
      });

      const lang = this.form.lang;

      this.form = { ...newValue, salesmen, references };
      this.form.lang = lang;
    },
  },

  methods: {
    addSalesman() {
      if (this.form.salesmen.length >= 3) return;

      this.form.salesmen.push({
        id: new Date().toISOString(),
        name: '',
        phone: '',
        email: '',
        county: '',
      });
    },
    addReference() {
      const id = new Date().toISOString();

      this.form.references.push({
        id,
        title: '',
        text: '',
      });

      this.setFileUploadData([`reference_image_${id}`], [`reference_image_${id}`]);
    },
    removeSalesman(index) {
      this.form.salesmen.splice(index, 1);
    },
    removeReference(index) {
      this.form.references.splice(index, 1);
    },
    validationForm() {
      this.$refs.forDesignersValidation.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    onSubmit() {
      if (this.form.references.length) {
        this.form.references.forEach((reference, index) => {
          this.form.references[index].image = this.files[`reference_image_${reference.id}`];
        });
      }

      const data = {
        ...this.form,
        title_image: this.files.title_image,
        three_d_models_image: this.files.three_d_models_image,
        catalog_image: this.files.catalog_image,
        documentation_image: this.files.documentation_image,
        lang: this.form.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.form.lang.lang);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.info-icon {
  transform: translateY(-1px);
  margin-left: 5px;
  cursor: pointer;
}
</style>
