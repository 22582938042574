<script>
export default {
  data() {
    return {
      loader: null,
    };
  },

  methods: {
    showLoader(element = null) {
      this.loader = this.$loading.show({
        container: element,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
  },
};
</script>
