<template>
  <b-card ref="formContainer">
    <form-component :page="forDesigners" @onSubmit="onSubmit" @fetchByLocale="fetchForDesigners" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/for-designers-page/FormComponent';
import LoaderMixin from '@/mixins/LoaderMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, submit } from '@/http/static-pages/for-designers';

export default {
  mixins: [LoaderMixin, NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      forDesigners: {
        salesmen: [
          {
            year: '',
            content: '',
          },
        ],
      },
      imageUrl: null,
    };
  },

  methods: {
    fetchForDesigners(lang = 'pl') {
      show(lang)
        .then(({ data: { data } }) => {
          if (!data) {
            return;
          }

          this.forDesigners = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const form = new FormData();

      form.append('lang', data.lang);
      form.append('title', data.title);
      form.append('header', data.header);
      form.append('introduce', data.introduce);

      form.append('catalog_title', data.catalog_title);
      form.append('catalog_link', data.catalog_link);
      form.append('documentation_title', data.documentation_title);
      form.append('documentation_link', data.documentation_link);
      form.append('three_d_models_title', data.three_d_models_title);
      form.append('three_d_models_file', data.three_d_models_file);

      form.append('title_image', data.title_image.file, data.title_image.fileName);
      form.append('three_d_models_image', data.three_d_models_image.file, data.three_d_models_image.fileName);
      form.append('catalog_image', data.catalog_image.file, data.catalog_image.fileName);
      form.append('documentation_image', data.documentation_image.file, data.documentation_image.fileName);

      if (data.salesmen?.length) {
        data.salesmen.forEach((timeline, index) => {
          form.delete(`salesmen[${index}][id]`);

          form.append(`salesmen[${index}][name]`, timeline.name);
          form.append(`salesmen[${index}][email]`, timeline.email);
          form.append(`salesmen[${index}][phone]`, timeline.phone);
          form.append(`salesmen[${index}][county]`, timeline.county);
        });
      }

      if (data.references.length) {
        data.references.forEach((reference, index) => {
          form.delete(`references[${index}][id]`);

          form.append(`references[${index}][title]`, reference.title);
          form.append(`references[${index}][text]`, reference.text);
          form.append(`references[${index}][image]`, reference.image.file, reference.image.fileName);
        });
      }

      this.showLoader(this.$refs.formContainer);

      submit(form)
        .then(({ data: { data } }) => {
          this.aboutUs = data;
          this.showSuccessNotification('Dane zapisane', 'Strona została zaktualizowana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>
